.footer {
  padding-top: 32px;
  background-color: var(--color-white-linen);
  transition: var(--layout-theme-change-transition);
}

.footer.checkout {
  background-color: rgb(var(--greyscale-level-6));
}

.wrapper {
  margin: 0 auto;
  display: flex;
  padding: 16px;
  flex-direction: column-reverse;
  gap: 48px;
  justify-content: center;
  align-items: flex-start;
  white-space: nowrap;
  width: 100%;
}

.linkList {
  display: flex;
  flex-direction: column;
  gap: 32px;
  width: 100%;
  justify-content: flex-end;
}

@media only screen and (max-width: 1048px) {
  .linkList > span {
    padding-left: 16px;
    text-align: center;
  }

  .linkList::before,
  .linkList::after {
    background: RGBA(var(--greyscale-level-1), 0.08);
    border: 0;
    display: block;
    font-size: 0;
    height: 1px;
    line-height: 0;
    margin: 0;
    min-height: 1px;
    overflow: hidden;
    content: '';
  }

  .linkList::before {
    margin-bottom: 16px;
  }

  .linkList::after {
    margin-top: 16px;
  }
}

@media only screen and (min-width: 1048px) {
  .footer {
    padding-top: 48px;
  }

  .linkList {
    flex-direction: row;
    gap: 60px;
  }

  .wrapper {
    max-width: var(--max-footer-width);
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    padding: 16px;
  }
}
