@media only screen and (min-width: 721px) {
  .modal {
    width: auto;
    height: auto;
    min-width: 400px;
    max-width: 450px;
    max-height: 800px;
  }
}

@media only screen and (max-width: 720px) {
  .modal {
    display: flex;
    flex-direction: column;
  }

  .fullscreen {
    border-radius: 0 !important;
    max-height: 100% !important;
    height: 100% !important;
    width: 100% !important;
  }
}
