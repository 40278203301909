.wrapper {
    position: fixed;
    right: 0;
    top: 55vh;
    
    border-radius: var(--spacing-regular) var(--spacing-regular) 0 0;
    border: 1px solid rgba(var(--amplified-default, 1));
    
    background-color: rgb(var(--amplified-default));

    -webkit-transform: rotate(270deg);
    -ms-transform: rotate(270deg);  
    transform: rotate(270deg);

    transform-origin: bottom right;
}
  
@media only screen and (max-width: 1047px) {
    .wrapper {
        display: none;
    }
}
