.navigation {
  height: 100%;
}

.menuMobile {
  height: 100%;
  display: flex;
  align-items: center;
}

.menuDesktop {
  display: none;
}

@media only screen and (min-width: 1048px) {
  .menuMobile {
    display: none;
  }

  .menuDesktop {
    height: 100%;
    display: flex;
    align-items: center;
  }
}
