.content {
  height: 100%;

  display: flex;
  flex-direction: column;
  justify-content: space-between;
  
  overflow: hidden;
  border-radius: inherit;
}

@media only screen and (max-width: 720px) {
  .body {
    height: 100%;
  }
}

@media only screen and (min-width: 721px) {
  .cookieSettingsModal {
    width: 680px !important;
  }
}
