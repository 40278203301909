/* TODO: figure out which Text type to use instead of overriding Web-ui for this specific case. */
.menuMobile :global(.web_ui__Text__text) {
  font-weight: 600;
  font-style: normal;
  font-family: 'V-RobotoFlex';
}

.menuMobile {
  display: flex;
}

.menuMobile li {
  list-style: none;
}

.menuMobile a {
  width: 100%;
  text-align: center;
}

.menuMobile button {
  display: flex;
}

.menuOverlay {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  position: fixed;
  top: var(--header-height);
  right: 0;
  bottom: 0;
  left: 0;
  overflow: hidden;
  overscroll-behavior: contain;
  z-index: var(--header-z-index);
  padding: 0 var(--spacing-xx-large);
  background-color: var(--color-white-linen);
  transition: var(--layout-theme-change-transition);
}

.menuOverlay.checkout {
  background-color: rgb(var(--greyscale-level-7));
}

.noScroll {
  height: 100%;
  overflow: hidden;
}
