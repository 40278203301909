html,
body {
  padding: 0;
  margin: 0;
  font-family: 'V-Inter', 'Helvetica Neue', 'Helvetica-Neue', 'Arial', sans-serif;
}

/*
  Allow percentage-based heights in the application
*/
html,
#__next {
  height: 100%;
}

body {
  min-height: 100%;
}

/*
  Typographic tweaks!
  Add accessible line-height
  Improve text rendering
*/
body {
  line-height: 1.5;
  -webkit-font-smoothing: antialiased;
}

a {
  color: inherit;
  text-decoration: none;
}

/*
  Use a more-intuitive box-sizing model.
*/
*,
*::before,
*::after {
  box-sizing: border-box;
}

/*
  Remove default margin
*/
* {
  margin: 0;
}

/*
  Improve media defaults
*/
img,
picture,
video,
canvas,
svg {
  display: block;
  max-width: 100%;
}

/*
  Remove built-in form typography styles
*/
input,
button,
textarea,
select {
  font: inherit;
}

/*
  Avoid text overflows
*/
p,
h1,
h2,
h3,
h4,
h5,
h6 {
  overflow-wrap: break-word;
}

/*
  Create a stacking context, without a z-index.
  This ensures that all portal content (modals and tooltips) will
  float above the app.
*/
#__next {
  isolation: isolate;
}
