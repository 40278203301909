.rootGridContainer {
  display: grid;
  grid-template-rows: auto 1fr;
  min-height: 100vh;
}

.headerContainer {
  grid-row: 1;
}

.headerContainer.sticky {
  position: sticky;
  top: 0;
  z-index: var(--header-z-index);
}

.contentContainer {
  grid-row: 2;
  background-color: var(--color-white-linen);
  transition: var(--layout-theme-change-transition);
}

.checkout > .contentContainer {
  background-color: rgb(var(--greyscale-level-6));
}

.mainContainer {
  width: 100vw;
}

.mainContainer.widthDefault {
  max-width: 1048px;
  margin: 0 auto;
  padding: 0 16px;
}
