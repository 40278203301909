.title {
  font-family: 'V-RobotoFlex';
  font-weight: 600;
  font-size: 16px;
  line-height: 32px;
  color: rgba(var(--greyscale-level-1));

  height: 100%;
  display: flex;
  justify-content: space-around;
}

.dropdown {
  height: 100%;
  position: relative;
  display: inline-block;
}

.dropdownContent {
  display: none;

  position: absolute;
  z-index: 1;
  right: 0;
  white-space: nowrap;
}

.dropdownContent :global(.web_ui__Card__card) {
  border-top-right-radius: 0;
}

.dropdownContentItem {
  background-color: rgb(var(--backgrounds-primary));
}

.dropdownContentItem.checkout {
  background-color: rgb(var(--greyscale-level-7));
}

.activePageHighlighter {
  display: none;

  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
}

.dropdown:hover .dropdownContent {
  display: block;
}

.dropdown:hover .activePageHighlighter {
  display: block;
}
