:root {
  --spacing-unit: 4px;
  --spacing-x-small: calc(var(--spacing-unit) * 0.5);
  --spacing-small: calc(var(--spacing-unit) * 1);
  --spacing-regular: calc(var(--spacing-unit) * 2);
  --spacing-medium: calc(var(--spacing-unit) * 3);
  --spacing-large: calc(var(--spacing-unit) * 4);
  --spacing-x-large: calc(var(--spacing-unit) * 6);
  --spacing-xx-large: calc(var(--spacing-unit) * 8);
  --spacing-xxx-large: calc(var(--spacing-unit) * 12);
  --spacing-xxxx-large: calc(var(--spacing-unit) * 16);
  --spacing-xxxxx-large: calc(var(--spacing-unit) * 24);
  --spacing-xxxxxx-large: calc(var(--spacing-unit) * 32);
}
