.cookieTableRowContainer {
  display: grid;
  grid-template-columns: 90px 110px auto;
}

@media only screen and (min-width: 721px) {
  .cookieTableRowContainer {
    grid-template-columns: 180px 110px auto;
  }
}
