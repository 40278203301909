/* TODO: figure out which Text type to use instead of overriding Web-ui for this specific case. */
.menuDesktop :global(.web_ui__Text__text) {
  font-weight: 600;
  font-style: normal;
  font-family: 'V-RobotoFlex';
}

.menuDesktop {
  height: 100%;
  display: flex;
  align-items: center;
}

.menuDesktop li {
  height: 100%;
  list-style: none;
}

.menuDesktop a {
  height: 100%;
  display: flex;
  justify-content: space-around;
  position: relative;
}

.activePageHighlighter {
  display: none;

  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
}

.activePageHighlighter.highlighterVisible {
  display: block;
}

.menuDesktop li:hover .activePageHighlighter {
  display: block;
}
