.u-overflow-hidden {
  overflow: hidden;
  border-radius: inherit;
}

.u-overflow-wrap-anywhere {
  overflow-wrap: anywhere;
}

.u-word-break-break-all {
  word-break: break-all;
}

.u-overflow-auto {
  overflow: auto !important;
}

.u-fill-width {
  width: 100%;
}

.u-fill-height {
  height: 100%;
}

.u-flexbox {
  display: flex;
}

.u-flexbox-inline {
  display: inline-flex;
}

.u-flex {
  flex: 1;
}

.u-flex-auto {
  flex: auto;
}

.u-flex-direction-row {
  flex-direction: row;
}

.u-flex-direction-column {
  flex-direction: column;
}

.u-justify-content-center {
  justify-content: center;
}

.u-justify-content-flex-end {
  justify-content: flex-end;
}

.u-justify-content-space-between {
  justify-content: space-between;
}

.u-align-items-center {
  align-items: center;
}

.u-align-items-flex-end {
  align-items: flex-end;
}

.u-no-wrap {
  white-space: nowrap !important;
}

.u-padding-top-none > * {
  padding-top: 0;
}

.u-padding-bottom-none > * {
  padding-bottom: 0;
}

.u-cursor-pointer {
  cursor: pointer;
}

.u-position-relative {
  position: relative;
}
