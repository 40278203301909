.cookieBannerOverlay {
  background-color: rgba(var(--greyscale-level-1), .48);

  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  z-index: 999;
}

.cookieBannerContainer {
  position: fixed;
  bottom: 0;
  left: 0;
  margin: var(--spacing-x-large);
  
  z-index: 1000;
  overflow: hidden;
  max-width: 795px;
  border-radius: 8px;
  background-color: var(--greyscale-level-2);

  filter: drop-shadow(0px 8px 24px rgba(17, 17, 17, 0.24));
}

.cookieBannerContent {
  padding: var(--spacing-x-large);
}

.cookieBannerActionButtons {
  display: flex;
  flex-wrap: wrap;
  gap: var(--spacing-large);
}

.cookieBannerAcceptButton,
.cookieBannerRejectButton {
  width: 100%;
}

.cookieBannerSettingsButton {
  margin: 0 auto;
}

@media only screen and (min-width: 721px) {
  .cookieBannerAcceptButton,
  .cookieBannerRejectButton {
    width: 196px;
  }  

  .cookieBannerSettingsButton {
    margin-left: auto;
  }
}