.wrapper {
  background-color: var(--color-white-linen);
  height: var(--header-height);
  transition: var(--layout-theme-change-transition);
}

.wrapper.checkout {
  background: rgb(var(--greyscale-level-7));
}

.content {
  height: 100%;
  padding: 0 16px;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.logo {
  position: relative;
  width: 220px;
  height: 32px;
}

/* Before this break point, order summary content in english starts to break */
@media only screen and (min-width: 1048px) {
  .content {
    max-width: 1280px;
  }
}
